module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PZ65WH6","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","enableListener":true,"custom":[{"name":["helvetica-neue-lt"],"file":"/fonts/helvetica-neue-lt/stylesheet.css"},{"name":["druk-text"],"file":"/fonts/druk-text/stylesheet.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-60,"duration":500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
